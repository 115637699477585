<template>
  <div class="banner-slider _swiper" :class="id">
    <div
      v-if="banners.length"
      v-swiper:sliderBanner="sliderOptions"
    >
      <ul class="swiper-wrapper banner-slider__wrapper">
        <li
          v-for="(banner, index) in banners"
          :key="index"
          class="swiper-slide banner-slider__item-wrapper"
        >
          <Banner :info="banner" loading="default" :ratio="ratio" :sizes="sizes" :index="index" />
        </li>
      </ul>
      <div class="banner-slider__controls">
        <div v-if="banners.length > 1" class="banner-slider__controls-wrapper">
          <div class="banner-slider__arrow banner-slider__arrow_prev">
            <div class="slider-arrow slider-arrow_prev">
              <SvgIcon name="arrow-small" width="24" height="24" />
            </div>
          </div>
          <div class="banner-slider__dotts"></div>
          <div class="banner-slider__arrow banner-slider__arrow_next">
            <div class="slider-arrow slider-arrow_next">
              <SvgIcon name="arrow-small" width="24" height="24" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {directive} from 'vue-awesome-swiper';
import Banner from '~/components/catalog/banner/index';

export default {
  name: 'BannerSlider',
  components: {
    Banner,
  },
  directives: {
    swiper: directive,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
    autoplay: {
      type: [Object, Boolean],
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sliderOptions: {
        autoplay: this.autoplay,
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        slidesPerView: 1,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        loop: true,
        preloadImages: false,
        pagination: {
          el: `.${this.id} .banner-slider__dotts`,
          clickable: false,
        },
        // Arrows
        navigation: {
          nextEl: `.${this.id} .banner-slider__arrow_next`,
          prevEl: `.${this.id} .banner-slider__arrow_prev`,
        },
      },
    }
  },
  computed: {
    swiperSlider() {
      return this.sliderBanner
    },
  },
  mounted() {
    if (!this.swiperSlider) return

    this.swiperSlider.on('realIndexChange', () => {
      this.$emit('changeSlide', this.swiperSlider.realIndex)
    })

    if (this.banners.length === 1) {
      this.swiperSlider.destroy()
    }
  },
}
</script>

