<template>
  <div class="dynamic-banner" :style="bannerStyle">
    <CustomLink class="dynamic-banner__wrapper" :to="info.url" @click.native="selectItemEvent(info, index)">
      <CustomImage
        v-if="image.url"
        :image="image.url"
        :media="mediaImage"
        :aspect-ratio="image.ratio"
        size="medium"
        :alt="info.name"
        :loading="loading"
      />
    </CustomLink>
    <Button
      v-if="info.button"
      :to="buttonLink.to"
      :href="buttonLink.href"
      class="dynamic-banner__button"
      :theme="themeButton"
      :class="{
        [`dynamic-banner__button--${info.button.location}`]: true,
      }"
    >
      {{ info.button.text }}
    </Button>
  </div>
</template>

<script>
import * as amplitudeTracker from '@amplitude/analytics-browser';
import Button from '@/components/elements/Button';
import CustomLink from '~/components/elements/CustomLink';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'Banner',
  components: {
    CustomImage,
    CustomLink,
    Button,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
  data() {
    const defaultRatio = {
      mobile: '100%',
      tablet: '27.1%',
      pc: '27.1%',
    }

    const mediaImage = []

    if (this.info?.mobileImage?.url) {
      mediaImage.push({
        url: this.info.mobileImage.url,
        media: '(max-width:574.98px)',
      })
    }

    if (this.info?.tabletImage?.url) {
      mediaImage.push({
        url: this.info.tabletImage.url,
        media: '(max-width:1023px)',
      })
    }

    return {
      mediaImage: mediaImage.filter(item => item?.url?.medium),

      bannerRatio: {},
      defaultRatio,

      image: {
        url: this.info.desktopImage.url,
        ratio: defaultRatio.pc,
      },
    }
  },
  computed: {
    themeButton() {
      return this.info.button.type
    },
    isExternalLink() {
      return typeof this.info.url === 'string' && this.info.url.startsWith('http')
    },
    buttonLink() {
      return {
        to: this.isExternalLink ? '' : this.info.url,
        href: this.isExternalLink ? this.info.url : '',
      }
    },
    mqIsMobileXs() {
      return this.$mq === 'mobileXs';
    },
    mqIsTablet() {
      return this.$mq === 'tablet' || this.$mq === 'mobile';
    },
    bannerStyle() {
      return {
        '--banner-ratio-pc': this.bannerRatio.pc,
        '--banner-ratio-tablet': this.bannerRatio.tablet,
        '--banner-ratio-mobile': this.bannerRatio.mobile,
        '--banner-radius': this.borderRadius,
      }
    },
  },
  watch: {
    '$mq': {
      handler(value) {
        switch (value) {
          case 'mobileXs':
            if (this.mediaImage.length) {
              this.image.ratio = this.bannerRatio.mobile
            }
            break;
          case 'tablet':
          case 'mobile':
            if (this.mediaImage.length) {
              this.image.ratio = this.bannerRatio.tablet
            }
            break;
          default:
            this.image.ratio = this.bannerRatio.pc
        }
      },
      immediate: true,
    },
  },
  created() {
    Object.assign(this.bannerRatio, this.defaultRatio, this.ratio)
  },
  beforeMount() {
    if (this.mqIsTablet) {
      this.image.ratio = this.bannerRatio.tablet
    } else if (this.mqIsMobileXs) {
      this.image.ratio = this.bannerRatio.mobile
    } else {
      this.image.ratio = this.bannerRatio.pc
    }
  },
  methods: {
    selectItemEvent(info, index) {
      if (info && index !== null) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'select_promotion',
          ecommerce: {
            creative_name: info.name,
            promotion_name: 'banners',
          },
        });
        amplitudeTracker.track('click_banner', {
          banner_name: info.name,
          banner_position: `${index + 1}`,
        });
      }
    },
  },
}
</script>
